import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useContactsList() {
  // Use toast
  const toast = useToast()

  const refContactsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'email', sortable: true },
    { key: 'telefono', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'display_name',
    email: 'email',
    telefono: 'phone_number',
    utente: 'user',
  }
  const perPage = ref(10)
  const totalContacts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refContactsListTable.value ? refContactsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContacts.value,
    }
  })

  const refetchData = () => {
    refContactsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, userFilter], () => {
    refetchData()
  })

  const fetchContacts = (ctx, callback) => {
    store
      .dispatch('app-contacts/fetchContacts', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        user_id: userFilter.value,
      })
      .then(response => {
        const contacts = response.data[0]
        const total = response.data[1]

        callback(contacts)
        totalContacts.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'secondary'
  }

  return {
    fetchContacts,
    tableColumns,
    perPage,
    currentPage,
    totalContacts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refContactsListTable,

    refetchData,
    resolveUserRoleVariant,

    // Filters
    userFilter,
  }
}
