<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="BookIcon"
        size="25"
      />Contatti</h1>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <contacts-list-filters
        :user-filter.sync="userFilter"
        :user-options="users"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Cerca..."
              />
              <b-button
                variant="primary"
                class="text-nowrap"
                :to="{ name: 'apps-contacts-add' }"
              >Nuovo Contatto</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refContactsListTable"
        class="position-relative"
        :items="fetchContacts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Contatto corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="25"
                :text="avatarText(data.item.display_name)"
                variant="light-info"
                :to="{ name: 'apps-contacts-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-contacts-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.display_name }}
            </b-link>
          </b-media>
        </template>

        <template #cell(email)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>

        <template #cell(telefono)="data">
          <div class="text-nowrap">
            <feather-icon
              class="mr-1"
              :class="data.item.phone_number ? 'text-success' : 'text-danger'"
              :icon="data.item.phone_number ? 'PhoneIcon' : 'PhoneOffIcon'"
            />
            <span class="align-text-top">{{ data.item.phone_number }}</span>
          </div>
        </template>

        <template #cell(utente)="data">
          <div class="text-nowrap">
            <div
              v-if="data.item.user"
              class="d-flex align-items-center"
            >
              <b-avatar
                size="25"
                :src="data.item.user.avatar_file ? mediaUrl + data.item.user.avatar_file : ''"
                :text="avatarText(data.item.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
                class="mr-1"
              />
              <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }">
                #{{ data.item.user.anagrafico_id }} - {{ data.item.user.display_name }}
              </b-link>
            </div>
            <div
              v-else
              class="text-nowrap"
            >
              <feather-icon
                class="text-danger"
                icon="UserXIcon"
              />
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-contacts-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-contacts-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifica</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedContact = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="delete-modal"
        title="Cancella Contatto"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Cancella Contatto
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler cancellare definitivamente questo Contatto?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteContact(selectedContact)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BMedia,
  BLink,
  BImg,
  BButton,
  BModal,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/apps/user/userStoreModule'
import { useToast } from 'vue-toastification/composition'
import contactsStoreModule from '../contactsStoreModule'
import useContactsList from './useContactsList'
import ContactsListFilters from './ContactsListFilters.vue'

export default {
  components: {
    ContactsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BMedia,
    BLink,
    BImg,
    BButton,
    BModal,
    BAvatar,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const CONTACTS_APP_STORE_MODULE_NAME = 'app-contacts'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(CONTACTS_APP_STORE_MODULE_NAME, contactsStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACTS_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTACTS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000, include_me: true })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const {
      fetchContacts,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactsListTable,
      refetchData,

      resolveUserRoleVariant,

      userFilter,
    } = useContactsList()

    return {
      fetchContacts,
      tableColumns,
      perPage,
      currentPage,
      totalContacts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContactsListTable,
      refetchData,
      resolveUserRoleVariant,

      // Filter
      avatarText,
      userFilter,
      users,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 32,
        height: 32,
        class: 'm1',
      },
      selectedContact: null,
      selectedUser: null,
      observer: null,
      limit: 10,
      search: '',
      mediaUrl,
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    deleteContact(id) {
      store
        .dispatch('app-contacts/deleteContact', { id })
        .then(() => {
          this.selectedContact = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Contatto #${id} eliminato con successo`,
              icon: 'BookIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
